import React from "react";

const OfferIcon = ({ backgroundColor, textColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={textColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5004_20880)">
        <path
          d="M20.0414 8.7075V3.9975H15.3414L12.0014 0.6875L8.71141 3.9975H4.00141V8.7075L0.691406 11.9975L4.00141 15.3375V20.0375H8.71141L12.0014 23.3475L15.3414 20.0375H20.0414V15.3375L23.3514 11.9975L20.0414 8.7075ZM8.83141 7.0475C9.81141 7.0475 10.6014 7.8375 10.6014 8.8275C10.6014 9.29693 10.4149 9.74714 10.083 10.0791C9.75105 10.411 9.30084 10.5975 8.83141 10.5975C7.84141 10.5975 7.05141 9.8075 7.05141 8.8275C7.05141 7.8375 7.84141 7.0475 8.83141 7.0475ZM15.2214 16.9975C14.2414 16.9975 13.4514 16.1975 13.4514 15.2175C13.4514 14.7481 13.6379 14.2979 13.9698 13.9659C14.3018 13.634 14.752 13.4475 15.2214 13.4475C16.2014 13.4475 17.0014 14.2375 17.0014 15.2175C17.0014 15.6896 16.8139 16.1423 16.4801 16.4761C16.1462 16.81 15.6935 16.9975 15.2214 16.9975ZM8.50141 17.0275L7.00141 15.5275L15.5314 6.9975L17.0314 8.4975L8.50141 17.0275Z"
          fill={backgroundColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_5004_20880">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OfferIcon;
