import { Avatar } from "@mui/material";
import React from "react";

const CustomLogo = ({
  text,
  backgroundColor = "#475569",
  textColor = "white",
}) => {
  return (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        backgroundColor: backgroundColor,
        color: textColor,
        fontWeight: "bold",
        fontSize: 14,
      }}
    >
      {text}
    </Avatar>
  );
};
export default CustomLogo;
