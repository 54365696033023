import React from "react";

const ProfileIcon = ({ textColor, backgroundColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={textColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 21C16.9705 21 21 16.9705 21 12C21 7.0295 16.9705 3 12 3C7.0295 3 3 7.0295 3 12C3 16.9705 7.0295 21 12 21ZM12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        fill={backgroundColor}
      />
      <path
        d="M6 17.8149C6 17.2984 6.386 16.8619 6.9 16.8049C10.7575 16.3779 13.26 16.4164 17.109 16.8144C17.3012 16.8346 17.4834 16.9103 17.6333 17.0323C17.7831 17.1543 17.8942 17.3173 17.953 17.5014C18.0117 17.6856 18.0156 17.8828 17.9641 18.0691C17.9126 18.2554 17.808 18.4226 17.663 18.5504C13.1205 22.5099 10.5245 22.4554 6.32 18.5544C6.115 18.3644 6 18.0944 6 17.8154V17.8149Z"
        fill={backgroundColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0575 17.3116C13.239 16.9166 10.7725 16.8796 6.9545 17.3021C6.82882 17.3168 6.71296 17.3773 6.6291 17.472C6.54524 17.5668 6.49928 17.6891 6.5 17.8156C6.5 17.9586 6.5595 18.0941 6.66 18.1881C8.744 20.1211 10.323 20.9946 11.8665 21.0001C13.4155 21.0056 15.0795 20.1391 17.3345 18.1741C17.4061 18.1104 17.4577 18.0272 17.483 17.9346C17.5082 17.8421 17.506 17.7443 17.4767 17.6529C17.4473 17.5616 17.3921 17.4808 17.3177 17.4203C17.2433 17.3598 17.1529 17.3222 17.0575 17.3121V17.3116ZM6.845 16.3081C10.743 15.8766 13.282 15.9156 17.161 16.3171C17.4502 16.3473 17.7242 16.4611 17.9497 16.6447C18.1751 16.8282 18.3422 17.0736 18.4304 17.3506C18.5185 17.6276 18.524 17.9243 18.4462 18.2044C18.3683 18.4845 18.2105 18.7359 17.992 18.9276C15.7045 20.9216 13.7995 22.0076 11.8635 22.0001C9.922 21.9931 8.101 20.8886 5.9805 18.9211C5.82868 18.7797 5.70765 18.6085 5.62497 18.4182C5.5423 18.2279 5.49976 18.0226 5.5 17.8151C5.49927 17.4423 5.6361 17.0824 5.88429 16.8042C6.13247 16.5261 6.47455 16.3492 6.845 16.3076V16.3081Z"
        fill={backgroundColor}
      />
      <path
        d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10C8 8.93913 8.42143 7.92172 9.17157 7.17157C9.92172 6.42143 10.9391 6 12 6C13.0609 6 14.0783 6.42143 14.8284 7.17157C15.5786 7.92172 16 8.93913 16 10V10Z"
        fill={backgroundColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 13C12.7956 13 13.5587 12.6839 14.1213 12.1213C14.6839 11.5587 15 10.7956 15 10C15 9.20435 14.6839 8.44129 14.1213 7.87868C13.5587 7.31607 12.7956 7 12 7C11.2044 7 10.4413 7.31607 9.87868 7.87868C9.31607 8.44129 9 9.20435 9 10C9 10.7956 9.31607 11.5587 9.87868 12.1213C10.4413 12.6839 11.2044 13 12 13V13ZM12 14C13.0609 14 14.0783 13.5786 14.8284 12.8284C15.5786 12.0783 16 11.0609 16 10C16 8.93913 15.5786 7.92172 14.8284 7.17157C14.0783 6.42143 13.0609 6 12 6C10.9391 6 9.92172 6.42143 9.17157 7.17157C8.42143 7.92172 8 8.93913 8 10C8 11.0609 8.42143 12.0783 9.17157 12.8284C9.92172 13.5786 10.9391 14 12 14V14Z"
        fill={backgroundColor}
      />
    </svg>
  );
};

export default ProfileIcon;
