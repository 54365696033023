import React, { useEffect, useState } from "react";
import { TermsAndConditionsContainer } from "../../styles/styledComponent";
import TermsAndConditionsBlock from "./TermsAndConditionsBlock";
import useAppConfig from "../../hooks/useAppConfig";
import _ from "lodash";
import HeroBanner from "../../Layout/HeroBanner";
import AdditionalTextContent from "../LoyaltyInfo/AdditionalText";
import Footer from "../../Layout/Footer";

const TermsAndConditions = () => {
  const [bannerImg, setBannerImg] = useState();
  const [footerImg, setFooterImg] = useState();
  const pwaConfig = useAppConfig();
  const isAdditionalText =
    _.get(pwaConfig, "appConfig.config.additionalText.ALL_PAGES.html") ===
    "<p><br></p>";

  useEffect(() => {
    if (pwaConfig.appConfig.config.notForAllPage === "false") {
      setBannerImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.headerImage);
      setFooterImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.footerImage);
    } else {
      setBannerImg(
        pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.headerImage
          ? pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.headerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.headerImage
      );
      setFooterImg(
        pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.footerImage
          ? pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.footerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.footerImage
      );
    }
  }, [pwaConfig]);

  return (
    <TermsAndConditionsContainer>
      <HeroBanner src={bannerImg} />
      <TermsAndConditionsBlock />
      {!isAdditionalText && <AdditionalTextContent />}
      <Footer img={footerImg} />
    </TermsAndConditionsContainer>
  );
};

export default TermsAndConditions;
