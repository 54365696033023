import React from "react";

const LoyaltyIcon = ({ backgroundColor = "#475569", textColor = "white" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5534_28084)">
        <path
          d="M10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0229 4.47715 20.5 10 20.5C15.5229 20.5 20 16.0229 20 10.5C20 4.97715 15.5229 0.5 10 0.5ZM10.0659 3.99853L11.6419 8.91918L16.8067 9.03515L12.6135 12.0527L14.0991 17.0015L9.93287 13.9461L5.68603 16.8892L7.30468 11.9819L3.19335 8.85205L8.36058 8.87525L10.0659 3.99853Z"
          fill={backgroundColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_5534_28084">
          <rect
            width="20"
            height="20"
            fill={textColor}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoyaltyIcon;
