import React, { useState } from "react";
import useAppConfig from "../../hooks/useAppConfig";
import { Box, Typography, IconButton } from "@mui/material";
import TermsAndConditionsIcon from "../../assets/termsAndConditionsIcon";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import sanitizeHtml from "sanitize-html";
import { defaultOptions } from "../../shared/constants/HtmlValidation";
import "react-quill/dist/quill.snow.css";

const CustomAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const pwaConfig = useAppConfig();

  return (
    <Box mb={1} borderRadius={1} padding={2} sx={{ backgroundColor: "white" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer", WebkitTapHighlightColor: "transparent" }}
      >
        <Typography
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: pwaConfig?.appConfig?.config?.palette?.primary,
          }}
        >
          {title}
        </Typography>
        <IconButton>
          <KeyboardArrowUpOutlinedIcon
            style={{ transform: isOpen ? "rotate(0deg)" : "rotate(180deg)" }}
          />
        </IconButton>
      </Box>
   
      {isOpen && (
        <div className="ql-editor">
          <div
            className="additionalText"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content, defaultOptions),
            }}
          />
        </div>
      )}
    </Box>
  );
};

const TermsAndConditionsBlock = () => {
  const pwaConfig = useAppConfig();

  return (
    <Box paddingBottom={10}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={1}
        padding={2}
      >
        <TermsAndConditionsIcon
          color={pwaConfig?.appConfig?.config?.palette?.primary}
        />
        <Typography
          style={{ fontSize: 20, fontWeight: 700 }}
          textAlign="center"
        >
          Terms and Conditions
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        height={"100%"}
        sx={{ backgroundColor: "#F7F7F7" }}
        padding={2}
      >
        {pwaConfig.appConfig?.config?.termsAndConditions ? (
          pwaConfig.appConfig.config.termsAndConditions.map((terms, index) => (
            <CustomAccordion
              key={index}
              title={terms.heading}
              content={terms.content}
            />
          ))
        ) : (
          <Typography textAlign={"center"} fontWeight={"bold"}>
            Terms And Conditions will be updated soon!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TermsAndConditionsBlock;
