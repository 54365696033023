import React from "react";

const CollectionsIcon = ({ backgroundColor, textColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={textColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill={backgroundColor} />
      <path
        d="M21.4641 14.5359L14.0086 15.4789L9.4641 21.4641L8.52103 14.0086L2.5359 9.46408L9.99142 8.52102L14.5359 2.53588L15.479 9.9914L21.4641 14.5359Z"
        fill="white"
      />
    </svg>
  );
};

export default CollectionsIcon;
